import { Paper, Grid, ButtonGroup, Button, Typography } from "@mui/material";
import { ApplicantsStore } from "../../../../Store";
import TableViewIcon from "@mui/icons-material/TableView";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default function JobsManager(props) {
  const AppCounts = ApplicantsStore((state) => state.AppCounts);
  const ApplicantsList = ApplicantsStore((state) => state.ApplicantsList);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    csvData = [
      {
        id: "#",
        jobref: "Job Reference",
        jobname: "Job Name",
        fn: "Firstname",
        ln: "Lastname",
        ge: "Gender",
        dob: "Date of Birth",
        phone: "Phone",
        email: "Email",
        country: "Country",
        province: "Province",
        district: "District",
        education: "Education",
        study: "Field of Study",
        experience: "Experience",
        wfib: "Worked For Agency Before",
        nationality: "Nationality",
        languages: "Languages",
        wore: "Working relative in Agency",
        worefn: "Relative Fullname",
        worere: "Relative Relationship",
      },
      ...csvData,
    ];
    const ws = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4} lg={3} sx={{ my: 1 }}>
        <Typography variant="h6">
          Total Applicants : <span className="bolder">{AppCounts}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} sx={{ my: 1, textAlign: "end" }}>
        <ButtonGroup>
          <Button
            color="success"
            size="small"
            onClick={(e) =>
              exportToCSV(
                ApplicantsList,
                "Full Applicant List For " + props.ExportDate
              )
            }
          >
            <TableViewIcon sx={{ mx: 1 }} />
            Export As Excel
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
