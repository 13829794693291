import {
  Box,
  Paper,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddIcon from "@mui/icons-material/Add";
import { AdminsStore } from "../../../../Store";
export default function ListAdmins() {
  const adminslist = AdminsStore((state) => state.adminslist);
  const SetAddAdmin = AdminsStore((state) => state.SetAddAdmin);
  const SetEditAdmin = AdminsStore((state) => state.SetEditAdmin);
  const SetDeleteAdmin = AdminsStore((state) => state.SetDeleteAdmin);
  const SetadminID = AdminsStore((state) => state.SetadminID);
  const OpendEditor = (param) => {
    SetadminID(param);
    SetEditAdmin();
  };
  const OpendDelete = (param) => {
    SetadminID(param);
    SetDeleteAdmin();
  };
  return (
    <Box>
      <Box sx={{ my: 3 }}>
        <Typography variant="h5" color={"#2f3193"}>
          System Admins
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box sx={{ textAlign: "end" }}>
          <Button variant="contained" color="primary" onClick={SetAddAdmin}>
            <AddIcon sx={{ mr: 2 }} />
            Add New
          </Button>
        </Box>
        <TableContainer sx={{ my: 3 }}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell sx={{ flex: 1 }}>Full Name</TableCell>
                <TableCell sx={{ flex: 1 }}>Username</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Manage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminslist.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="primary"
                      aria-label="menu"
                      sx={{ m: 0, p: 1 }}
                      title="Edit Admin"
                      onClick={(adid) => OpendEditor(row.id)}
                    >
                      <BorderColorIcon />
                    </IconButton>
                    <IconButton
                      size="large"
                      edge="start"
                      color="error"
                      aria-label="menu"
                      sx={{ m: 0, p: 1 }}
                      title="Delete Admin"
                      onClick={(adid) => OpendDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
