import { Grid, Alert, Divider, Button, Typography } from "@mui/material";
import { ApplicationStore } from "../../../../../Store";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
export default function ResponseMessage() {
  const RespMess = ApplicationStore((state) => state.RespMess);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ResetApplication = () => {
    SetActiveStep(0);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6">Application Finished</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 5, py: 5 }}>
        {RespMess === 1 && (<Alert
            variant="outlined"
            severity="success"
            sx={{ textAlign: "center" }}
          >
            Your Application Had Been Subsmitted Successfully
          </Alert>)}
          {RespMess === 2 && (<Alert
            variant="outlined"
            severity="warning"
            sx={{ textAlign: "center" }}
          >
            Your Email Address Already Applied for this position
          </Alert>)}
          {RespMess === 0 && (<Alert
            variant="outlined"
            severity="error"
            sx={{ textAlign: "center" }}
          >
            Application Submittion Error
          </Alert>)}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ my: 1, textAlign: "end" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={ResetApplication}
          size="small"
        >
          <RestartAltIcon sx={{ mx: 1 }} />
          Re-Start Application
        </Button>
      </Grid>
    </Grid>
  );
}
