import { Paper, Box, Button } from "@mui/material";
import { ApplicationStore } from "../../../../Store";
import {
  PersonalInfo,
  ContactInfo,
  EducationNat,
  IOMExp,
  Ioa,
  CV,
  JobNotFound,
  ApplicationClosed,
  ResponseMessage,
  PreviewInfo,
} from "./Steps";
export default function ApplicationForm() {
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const Jinfo = ApplicationStore((state) => state.Jinfo);
  const StepViewer = (param) => {
    if (param === 0) {
      return <PersonalInfo />;
    } else if (param === 1) {
      return <ContactInfo />;
    } else if (param === 2) {
      return <EducationNat />;
    } else if (param === 3) {
      return <IOMExp />;
    } else if (param === 4) {
      return <Ioa />;
    } else if (param === 5) {
      return <CV />;
    } else if (param === 6) {
      return <PreviewInfo />;
    } else {
      return <ResponseMessage />;
    }
  };
  const ViewHolder = (param, param2) => {
    if (param === null || param[0].deleted === 1) {
      return <JobNotFound />;
    } else {
      if (param[0].opened === 1) {
        return <Box>{StepViewer(param2)}</Box>;
      } else {
        return <ApplicationClosed />;
      }
    }
  };
  return (
    <Paper elevation={3} sx={{ my: 2, p: 2 }}>
      {ViewHolder(Jinfo, ActiveStep)}
    </Paper>
  );
}
