import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { APIURL } from "../../../../Context/configs";
import { SnLStore, VacanciesStore } from "../../../../Store";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
export default function NewOrg() {
  const setOrgsList = VacanciesStore((state) => state.setOrgsList);
  const OrgsList = VacanciesStore((state) => state.OrgsList);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetSnackMessage = SnLStore((state) => state.SetSnackMessage);
  const SetSnackBarShow = SnLStore((state) => state.SetSnackBarShow);
  const SetSnackBarType = SnLStore((state) => state.SetSnackBarType);
  const SetEditAdmin = VacanciesStore((state) => state.SetEditAdmin);
  const EditAdmin = VacanciesStore((state) => state.EditAdmin);
  const CloseDialog = () => {
    SetEditAdmin();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "admin/AddOrg.php", {
      method: "post",
      body: JSON.stringify({
        on: data.get("on"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          if (item.id === 1) {
            SetSnackBarType("success");
            fetch(APIURL + "admin/ListOrgs.php")
              .then((res) => res.json())
              .then((result) => {
                setTimeout(() => {
                  SetLoadingShow();
                  SetSnackBarShow();
                  setOrgsList(result);
                }, 2000);
              });
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  const updateRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "admin/EditOrg.php", {
      method: "post",
      body: JSON.stringify({
        on: data.get("on"),
        id: data.get("id"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          if (item.id === 1) {
            SetSnackBarType("success");
            fetch(APIURL + "admin/ListOrgs.php")
              .then((res) => res.json())
              .then((result) => {
                setTimeout(() => {
                  SetLoadingShow();
                  SetSnackBarShow();
                  setOrgsList(result);
                }, 2000);
              });
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={EditAdmin} onClose={CloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle color={"#2f3193"}>Organizations</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Typography variant="h6" sx={{ mb: 4 }}>
              Organizations List
            </Typography>
            {OrgsList.map((O) => (
              <Box
                component="form"
                onSubmit={updateRequest}
                key={O.org_id}
                sx={{ display: "flex", my: 2 }}
              >
                <Box className="d-none">
                  <TextField id="id" name="id" value={O.org_id} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    id="on"
                    name="on"
                    label="Organization Name"
                    size="small"
                    fullWidth
                    defaultValue={O.org_name}
                  />
                </Box>
                <Box sx={{ mx: 1 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ py: 1 }}
                  >
                    <ModeEditIcon />
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <Box sx={{ px: 5 }}>
              <Typography variant="h6" sx={{ mb: 4 }}>
                Add Organization
              </Typography>
              <Box
                component="form"
                onSubmit={sendRequest}
                sx={{ display: "flex" }}
              >
                <Box sx={{ flex: 1 }}>
                  <TextField
                    id="on"
                    name="on"
                    label="Organization Name"
                    size="small"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mx: 1 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ py: 1 }}
                  >
                    <SaveIcon />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ textAlign: "end" }}>
            <Button onClick={CloseDialog} variant="contained" color="error">
              Close Dialog
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
