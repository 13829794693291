import { create } from "zustand";
const VacanciesStore = create((set) => ({
  VacList: [],
  OrgsList: [],
  AddAdmin: false,
  EditAdmin: false,
  DeleteAdmin: false,
  VacID: 0,
  OrgID: 0,
  SetVacID: (st) => set((state) => ({ VacID: st })),
  SetOrgID: (st) => set((state) => ({ OrgID: st })),
  setVacList: (data) => set((state) => ({ VacList: data })),
  setOrgsList: (data) => set((state) => ({ OrgsList: data })),
  SetAddAdmin: () => set((state) => ({ AddAdmin: !state.AddAdmin })),
  SetEditAdmin: () => set((state) => ({ EditAdmin: !state.EditAdmin })),
  SetDeleteAdmin: () => set((state) => ({ DeleteAdmin: !state.DeleteAdmin })),
}));

export default VacanciesStore;
