import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { NavLink } from "react-router-dom";
import { ActionMessage, Loadin } from "../Elements/Common";
import { ClearCache } from "../Elements/Settings";
export default function Settings() {
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    document.title = "StarsOrbit Jobs Portal - System Settings";
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">System Settings</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <ClearCache />
      )}
      <ActionMessage />
      <Loadin />
    </Container>
  );
}
