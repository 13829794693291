import { create } from "zustand";
import Cookies from "js-cookie";
const userLoginStore = create((set) => ({
  isLoggedIn: Cookies.get("loggedIn"),
  userID: Cookies.get("userID"),
  setIsLoggedIn: () => set((state) => ({ isLoggedIn: !state.isLoggedIn })),
  setuserID: (id) => set((state) => ({ userID: id })),
}));

export default userLoginStore;
