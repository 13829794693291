import { Box, Grid, Button, Typography, Divider, Paper } from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import HTMLReactParser from "html-react-parser";
import { APIURL } from "../../../../../Context/configs";
import { useEffect } from "react";
export default function PreviewInfo() {
  const FirstName = ApplicationStore((state) => state.FirstName);
  const LastName = ApplicationStore((state) => state.LastName);
  const Gender = ApplicationStore((state) => state.Gender);
  const Dob = ApplicationStore((state) => state.Dob);
  const PhoneNumber = ApplicationStore((state) => state.PhoneNumber);
  const Email = ApplicationStore((state) => state.Email);
  const Country = ApplicationStore((state) => state.Country);
  const Province = ApplicationStore((state) => state.Province);
  const District = ApplicationStore((state) => state.District);
  const EducationLevel = ApplicationStore((state) => state.EducationLevel);
  const FieldOfStudy = ApplicationStore((state) => state.FieldOfStudy);
  const YearsOfExp = ApplicationStore((state) => state.YearsOfExp);
  const Nationality = ApplicationStore((state) => state.Nationality);
  const Langauges = ApplicationStore((state) => state.Langauges);
  const WFIB = ApplicationStore((state) => state.WFIB);
  const WRFI = ApplicationStore((state) => state.WRFI);
  const RelName = ApplicationStore((state) => state.RelName);
  const RelRelation = ApplicationStore((state) => state.RelRelation);
  const IOA = ApplicationStore((state) => state.IOA);
  const CVFile = ApplicationStore((state) => state.CVFile);
  const CVExt = ApplicationStore((state) => state.CVExt);
  const CertExt = ApplicationStore((state) => state.CertExt);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const Jinfo = ApplicationStore((state) => state.Jinfo);
  const CountryList = ApplicationStore((state) => state.CountryList);
  const ProvinceList = ApplicationStore((state) => state.ProvinceList);
  const SetRespMess = ApplicationStore((state) => state.SetRespMess);
  const randNum = ApplicationStore((state) => state.randNum);
  useEffect(() => {
    console.log(CVExt)
  }, [])

  const Goback = () => {
    SetActiveStep(ActiveStep - 1);
  };
  const SubmitData = () => {
    SetLoadingShow();
    fetch(APIURL + "application/SubmitApp.php", {
      method: "post",
      body: JSON.stringify({
        jd: Jinfo[0].id,
        fn: FirstName,
        ln: LastName,
        ge: Gender,
        dob: Dob,
        ph: PhoneNumber,
        em: Email,
        cn: Country,
        gn: Province,
        dis: District,
        el: EducationLevel,
        fos: FieldOfStudy,
        yos: YearsOfExp,
        wfib: WFIB,
        na: Nationality,
        lng: Langauges,
        wr: WRFI,
        rn: RelName,
        rr: RelRelation,
        rand: randNum,
        iow: IOA,
        cvext: CVExt,
        certext: CertExt,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetRespMess(item.id);
          setTimeout(() => {
            SetLoadingShow();
            SetActiveStep(ActiveStep + 1);
          }, 2000);
        });
      });
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6">Preview Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ fontWeight: "bold" }}>
          <Box>
            First Name : <span className="bolder">{FirstName}</span>
          </Box>
          <Box>
            Last Name : <span className="bolder">{LastName}</span>
          </Box>
          <Box>
            Gender : <span className="bolder">{Gender}</span>
          </Box>
          <Box>
            Date Of Birth : <span className="bolder">{Dob}</span>
          </Box>
          <Box>
            Phone Number : <span className="bolder">{PhoneNumber}</span>
          </Box>
          <Box>
            Email : <span className="bolder">{Email}</span>
          </Box>
          <Box>
            Country :{" "}
            <span className="bolder">
              {CountryList.filter((c) => c.cn_id === Country)[0].cn_name}
            </span>
          </Box>
          {Country === 105 && (
            <>
              <Box>
                Province :{" "}
                <span className="bolder">
                  {
                    ProvinceList.filter((p) => p.gov_id === Province)[0]
                      .gov_name
                  }
                </span>
              </Box>
              <Box>
                District : <span className="bolder">{District}</span>
              </Box>
            </>
          )}

          <Box>
            Education Level : <span className="bolder">{EducationLevel}</span>
          </Box>
          <Box>
            Field Of Study : <span className="bolder">{FieldOfStudy}</span>
          </Box>
          <Box>
            Years Of Experience : <span className="bolder">{YearsOfExp}</span>
          </Box>
          <Box>
            Nationality : <span className="bolder">{Nationality}</span>
          </Box>
          <Box>
            Languages : <span className="bolder">{Langauges}</span>
          </Box>
          <Box>
            Worked For Agency Before :{" "}
            <span className="bolder">{WFIB === "1" ? "Yes" : "No"}</span>
          </Box>
          <Box>
            Regardless of the nature of the relationship, do you have any relatives currently employed by Agency or SOC :{" "}
            <span className="bolder">{WRFI === "1" ? "Yes" : "No"}</span>
          </Box>
          {WRFI === 1 && (
            <>
              <Box>
                Relative Name : <span className="bolder">{RelName}</span>
              </Box>
              <Box>
                Relative Relationship :{" "}
                <span className="bolder">{RelRelation}</span>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <iframe
              className="w-100 vh-50"
              src={CVFile}
            ></iframe>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ fontWeight: "bold", mb: 2 }}>
            Interest of Application :{" "}
          </Box>
          <Box>{HTMLReactParser(IOA)}</Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ my: 1, textAlign: "end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="appbut"
            sx={{ mx: 1 }}
            onClick={Goback}
          >
            <NavigateBeforeIcon sx={{ mx: 1 }} />
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={SubmitData}
            size="small"
            className="appbut"
          >
            Submit
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
