import { create } from "zustand";
const AdminsStore = create((set) => ({
  adminslist: [],
  AddAdmin: false,
  EditAdmin: false,
  DeleteAdmin: false,
  adminID: 0,
  SetadminID: (st) => set((state) => ({ adminID: st })),
  setadminslist: (data) => set((state) => ({ adminslist: data })),
  SetAddAdmin: () => set((state) => ({ AddAdmin: !state.AddAdmin })),
  SetEditAdmin: () => set((state) => ({ EditAdmin: !state.EditAdmin })),
  SetDeleteAdmin: () => set((state) => ({ DeleteAdmin: !state.DeleteAdmin })),
}));

export default AdminsStore;
