import LOGO from "../../../../Assets/IMG/logo.png";
import { Img } from "react-image";
import { CircleLoader } from "../../../Common/Loaders";
import { Box } from "@mui/material";
export default function ApplicationHeader() {
  return (
    <Box>
      <Box sx={{ width: "100%", mt: 3, textAlign: "start" }}>
        <Img className="LoginImage" src={LOGO} loader={<CircleLoader />} />
      </Box>
    </Box>
  );
}
