import { Box, Paper, Typography, Button } from "@mui/material";
import { SnLStore } from "../../../../Store";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { APIURL } from "../../../../Context/configs";
export default function ClearCache() {
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetSnackMessage = SnLStore((state) => state.SetSnackMessage);
  const SetSnackBarShow = SnLStore((state) => state.SetSnackBarShow);
  const SetSnackBarType = SnLStore((state) => state.SetSnackBarType);
  const clearData = () => {
    SetLoadingShow();
    fetch(APIURL + "admin/ClearCache.php", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          setTimeout(() => {
            SetLoadingShow();
            SetSnackBarShow();
          }, 2000);
        });
      });
  };
  return (
    <Box>
      <Box sx={{ my: 3 }}>
        <Typography variant="h5" color={"#2f3193"}>
          System Admins
        </Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={clearData}
          >
            <DeleteSweepIcon sx={{ mr: 2 }} />
            Clear Files Cache
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
