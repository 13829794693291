import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Divider,
  TextField,
  MenuItem,
} from "@mui/material";
import { ApplicantsStore, SnLStore } from "../../../../Store";
import { APIURL } from "../../../../Context/configs";

export default function JobsEditor() {
  const OrgList = ApplicantsStore((state) => state.OrgList);
  const VacLIst = ApplicantsStore((state) => state.VacLIst);
  const SetVacLIst = ApplicantsStore((state) => state.SetVacLIst);
  const JobEditor = ApplicantsStore((state) => state.JobEditor);
  const SetJobEditor = ApplicantsStore((state) => state.SetJobEditor);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetSnackMessage = SnLStore((state) => state.SetSnackMessage);
  const SetSnackBarShow = SnLStore((state) => state.SetSnackBarShow);
  const SetSnackBarType = SnLStore((state) => state.SetSnackBarType);
  const CloseDialog = () => {
    SetJobEditor();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "admin/updateVac.php", {
      method: "post",
      body: JSON.stringify({
        vn: data.get("vn"),
        vr: data.get("vr"),
        org: data.get("org"),
        ed: data.get("ed"),
        wc: data.get("wc"),
        id: data.get("id"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          if (item.id === 1) {
            SetSnackBarType("success");
            fetch(APIURL + "admin/GetVacancy.php", {
              method: "post",
              body: JSON.stringify({
                id: data.get("id"),
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                setTimeout(() => {
                  CloseDialog();
                  SetLoadingShow();
                  SetSnackBarShow();
                  SetVacLIst(result);
                }, 2000);
              });
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              CloseDialog();
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={JobEditor} onClose={CloseDialog} maxWidth="md" fullWidth>
      <DialogTitle color={"#2f3193"}>
        Edit Vacancy {VacLIst[0].name} ({VacLIst[0].reference})
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
          <Divider />
        </Grid>
        <Box component="form" onSubmit={sendRequest} sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-none">
              <TextField
                id="id"
                name="id"
                required
                fullWidth
                defaultValue={VacLIst[0].id}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="vn"
                name="vn"
                required
                label="Vacancy Name"
                size="small"
                fullWidth
                autoFocus
                defaultValue={VacLIst[0].name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="vr"
                name="vr"
                required
                label="Vacancy Reference"
                size="small"
                fullWidth
                defaultValue={VacLIst[0].reference}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="org"
                name="org"
                required
                label="Organization"
                size="small"
                select
                fullWidth
                defaultValue={VacLIst[0].org_id}
              >
                {OrgList.map((O) => (
                  <MenuItem key={O.org_id} value={O.org_id}>
                    {O.org_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="ed"
                name="ed"
                required
                label="End Date"
                size="small"
                type="date"
                fullWidth
                defaultValue={VacLIst[0].end_at}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                id="wc"
                name="wc"
                required
                label="Require Certificate"
                size="small"
                select
                fullWidth
                defaultValue={VacLIst[0].with_cert}
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "end" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="small"
                sx={{ mx: 1 }}
              >
                Update Vacancy
              </Button>
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={CloseDialog}
              >
                Close Form
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
