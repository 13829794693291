import { Grid, TextField, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { ApplicantsStore } from "../../../../Store";
import { ApplicantListCol } from "../../../../Context/DataGrids";
export default function ApplicantsList() {
  const ApplicantsList = ApplicantsStore((state) => state.ApplicantsList);
  const [ItemsPerPage, SetItemsPerPage] = useState(25);
  const HandleChange = (e) => {
    SetItemsPerPage(e.target.value);
  };
  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid item xs={12} sm={12} md={8} lg={8}></Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <TextField
          select
          size="small"
          fullWidth
          label="Rows Per Page"
          defaultValue={ItemsPerPage}
          onChange={HandleChange}
        >
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={250}>250</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
        <DataGrid
          rows={ApplicantsList}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          columns={ApplicantListCol}
          pageSize={ItemsPerPage}
          rowsPerPageOptions={[ItemsPerPage]}
          disableSelectionOnClick
          autoHeight
          width={"100%"}
          disableColumnMenu
          density="compact"
          getRowId={(row) => row.id}
        />
      </Grid>
    </Grid>
  );
}
