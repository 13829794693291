import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
export default function ContactInfo() {
  const CountryList = ApplicationStore((state) => state.CountryList);
  const ProvinceList = ApplicationStore((state) => state.ProvinceList);
  const PhoneNumber = ApplicationStore((state) => state.PhoneNumber);
  const Email = ApplicationStore((state) => state.Email);
  const Country = ApplicationStore((state) => state.Country);
  const Province = ApplicationStore((state) => state.Province);
  const District = ApplicationStore((state) => state.District);
  const SetDistrict = ApplicationStore((state) => state.SetDistrict);
  const SetPhoneNumber = ApplicationStore((state) => state.SetPhoneNumber);
  const SetEmail = ApplicationStore((state) => state.SetEmail);
  const SetCountry = ApplicationStore((state) => state.SetCountry);
  const SetProvince = ApplicationStore((state) => state.SetProvince);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetContInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    SetPhoneNumber(data.get("ph"));
    SetEmail(data.get("em"));
    SetDistrict(data.get("di"));
    setTimeout(() => {
      SetActiveStep(ActiveStep + 1);
      SetLoadingShow();
    }, 2000);
  };
  const Goback = () => {
    SetActiveStep(ActiveStep - 1);
  };
  return (
    <Box component="form" onSubmit={SetContInfo} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6">Contact Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="ph"
            id="ph"
            label="Phone Number"
            required
            size="small"
            fullWidth
            type="tel"
            autoFocus
            defaultValue={PhoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="em"
            id="em"
            label="Email"
            required
            size="small"
            fullWidth
            type="email"
            defaultValue={Email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            name="cn"
            id="cn"
            label="Country"
            required
            size="small"
            fullWidth
            select
            onChange={(event) => SetCountry(event.target.value)}
            defaultValue={Country}
          >
            {CountryList.map((C) => (
              <MenuItem key={C.cn_code} value={C.cn_id}>
                {C.cn_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {Country === 105 && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="pr"
                id="pr"
                label="Province"
                required
                size="small"
                fullWidth
                select
                onChange={(event) => SetProvince(event.target.value)}
                defaultValue={Province}
              >
                {ProvinceList.map((P) => (
                  <MenuItem key={P.gov_id} value={P.gov_id}>
                    {P.gov_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="di"
                id="di"
                label="District"
                required
                size="small"
                fullWidth
                defaultValue={District}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ my: 1, textAlign: "end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="appbut"
            sx={{ mx: 1 }}
            onClick={Goback}
          >
            <NavigateBeforeIcon sx={{ mx: 1 }} />
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            className="appbut"
          >
            Next
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
