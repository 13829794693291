import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useState, useRef, useEffect } from "react";
export default function Ioa() {
  const InpREF = useRef('')
  const [txtlength, Settxtlength] = useState(0)
  const IOA = ApplicationStore((state) => state.IOA);
  const SetIOA = ApplicationStore((state) => state.SetIOA);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  useEffect(() => {
    Settxtlength(InpREF.current.value.length)
  }, [InpREF])
  
  const SetIOMInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    SetIOA(data.get("IOA"));
    setTimeout(() => {
      SetActiveStep(ActiveStep + 1);
      SetLoadingShow();
    }, 2000);
  };
  const Goback = () => {
    SetActiveStep(ActiveStep - 1);
  };
  const GetLength = (data) => {
    Settxtlength(data.length)
  }
  return (
    <Box component="form" onSubmit={SetIOMInfo} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6">Interest of Application</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            inputRef={InpREF}
            id="IOA"
            name="IOA"
            label="Interest of Application"
            required
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
            fullWidth
            defaultValue={IOA}
            onChange={(event) => GetLength(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography sx={{ color: "blue", fontSize: "small", textAlign: "right" }}>Maximum Characters {txtlength}/500</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography sx={{ color: "maroon", fontSize: "small" }}>* Maximum of 500 Characters.</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ my: 1, textAlign: "end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="appbut"
            sx={{ mx: 1 }}
            onClick={Goback}
          >
            <NavigateBeforeIcon sx={{ mx: 1 }} />
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            className="appbut"
          >
            Next
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
