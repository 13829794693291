import { Button, ButtonGroup, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { JobsListerGrid } from "../../../../Context/DataGrids";
import { VacanciesStore } from "../../../../Store";
import ListIcon from "@mui/icons-material/List";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
export default function VaLister() {
  const [JView, SetJView] = useState([]);
  const VacList = VacanciesStore((state) => state.VacList);
  useEffect(() => {
    SetJView(VacList);
  }, []);
  const ChangeStat = (param) => {
    if (param === "all") {
      SetJView(VacList);
    } else if (param === "opened") {
      SetJView(
        VacList.filter(
          (data) => parseInt(data.opened) === 1 && parseInt(data.deleted) === 0
        )
      );
    } else if (param === "closed") {
      SetJView(
        VacList.filter(
          (data) => parseInt(data.opened) === 0 && parseInt(data.deleted) === 0
        )
      );
    } else if (param === "deleted") {
      SetJView(VacList.filter((data) => parseInt(data.deleted) === 1));
    }
  };
  return (
    <Box>
      <Box sx={{ my: 2, textAlign: "center" }}></Box>
      <Box sx={{ my: 2, textAlign: "end" }}>
        <ButtonGroup>
          <Button
            onClick={() => ChangeStat("all")}
            color="primary"
            size="small"
          >
            <ListIcon />
            All Jobs
          </Button>
          <Button
            onClick={() => ChangeStat("opened")}
            color="success"
            size="small"
          >
            <PlaylistAddCheckIcon />
            Opened Jobs
          </Button>
          <Button
            onClick={() => ChangeStat("closed")}
            color="warning"
            size="small"
          >
            <PlaylistRemoveIcon />
            Closed Jobs
          </Button>
          <Button
            onClick={() => ChangeStat("deleted")}
            color="error"
            size="small"
          >
            <DeleteSweepIcon /> Deleted Jobs
          </Button>
        </ButtonGroup>
      </Box>
      <Box>
        <DataGrid
          rows={JView}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          columns={JobsListerGrid}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          autoHeight
          width={"100%"}
          disableColumnMenu
          density="compact"
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
}
