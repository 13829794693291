import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Box,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { NavLink } from "react-router-dom";
import { APIURL, FilterMonths } from "../../../Context/configs";
import { ApplicantsStore } from "../../../Store";
import { ActionMessage, Loadin } from "../Elements/Common";
import { ApplicantsList, JobsManager } from "../Elements/AllApplicants";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
export default function AllApplicants() {
  const [isLoading, setisLoading] = useState(true);
  const [isInfoLoading, setisInfoLoading] = useState(true);
  const [Submitted, isSubmitted] = useState(false);
  const SetApplicantsList = ApplicantsStore((state) => state.SetApplicantsList);
  const SetAppCounts = ApplicantsStore((state) => state.SetAppCounts);
  const [DateToView, SetDateToview] = useState("");
  const SetYear = new Date().getFullYear();
  const SetMonth = new Date().getMonth() + 1;
  const [YearsLister] = useState([]);
  useEffect(() => {
    document.title = "StarsOrbit Jobs Portal - All Applicants";
    setTimeout(() => {
      setisLoading(false);
      YearsLister.splice(0, YearsLister.length);
      for (let i = parseInt(new Date().getFullYear()); i > 2019; i--) {
        YearsLister.push({ y: i });
      }
    }, 3000);
  });
  const LoadApplicants = (event) => {
    event.preventDefault();
    isSubmitted(true);
    setisInfoLoading(true);
    const data = new FormData(event.currentTarget);
    let YNAME = data.get("yf");
    let SMNUMBER = data.get("mf");
    let EMNUMBER = data.get("emf");
    let results = FilterMonths.find(({ value }) => value === SMNUMBER);
    let SMNAME = results.label;
    let resulte = FilterMonths.find(({ value }) => value === EMNUMBER);
    let EMNAME = resulte.label;
    SetDateToview(YNAME + " From " + SMNAME + " To " + EMNAME);
    setTimeout(() => {
      fetch(APIURL + "admin/AllAppsTotal.php", {
        method: "post",
        body: JSON.stringify({
          dm: data.get("mf"),
          dy: data.get("yf"),
          emf: data.get("emf"),
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Request-Headers": "Content-Type, Authorization",
          "Access-Control-Allow-Credentials": "true",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result == null) {
          } else {
            result.forEach((item) => {
              SetAppCounts(item.TOT);
            });
          }
        });
      fetch(APIURL + "admin/AllApps.php", {
        method: "post",
        body: JSON.stringify({
          dm: data.get("mf"),
          dy: data.get("yf"),
          emf: data.get("emf"),
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Request-Headers": "Content-Type, Authorization",
          "Access-Control-Allow-Credentials": "true",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          SetApplicantsList(result);
          setisInfoLoading(false);
        });
    }, 2000);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          {isLoading ? (
            <Paper elevation={3} className="siteLoader">
              <RingLoader />
            </Paper>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <NavLink className="breabcrumblink" to="/admin">
                Admin Panel
              </NavLink>
              <Typography color="text.primary">All Applicants</Typography>
            </Breadcrumbs>
          )}
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <Box>
          <Typography variant="h6" color={"#2f3193"} sx={{ my: 3 }}>
            Applicants List
          </Typography>
          <Box component="form" onSubmit={LoadApplicants}>
            <Grid container spacing={3} sx={{ mb: 3, mt: 3 }}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={4} md={3} lg={3} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Year"
                        name="yf"
                        defaultValue={SetYear}
                      >
                        {YearsLister.map((y) => (
                          <MenuItem key={y.y} value={y.y}>
                            {y.y}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={3} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="Start Month"
                        defaultValue={SetMonth}
                        name="mf"
                      >
                        {FilterMonths.map((FM) => (
                          <MenuItem key={FM.value} value={FM.value}>
                            {FM.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} lg={3} sx={{ my: 1 }}>
                      <TextField
                        select
                        size="small"
                        fullWidth
                        label="End Month"
                        defaultValue={SetMonth}
                        name="emf"
                      >
                        {FilterMonths.map((FM) => (
                          <MenuItem key={FM.value} value={FM.value}>
                            {FM.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} sx={{ my: 1 }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        color="success"
                        type="submit"
                      >
                        <FilterAltIcon sx={{ mr: 2 }} /> Filter
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          {Submitted && (
            <>
              {isInfoLoading ? (
                <Paper elevation={3} className="siteLoader">
                  <RingLoader />
                </Paper>
              ) : (
                <>
                  <JobsManager ExportDate={DateToView} />
                  <ApplicantsList />
                </>
              )}
            </>
          )}
        </Box>
      )}
      <ActionMessage />
      <Loadin />
    </Container>
  );
}
