import { Grid, Alert, Divider,  Typography } from "@mui/material";

export default function JobNotFound() {
  return  <Grid container spacing={3}>
  <Grid item xs={12} sm={12} md={12} lg={12}>
    <Typography variant="h6">Application CLosed</Typography>
  </Grid>
  <Grid item xs={12} sm={12} md={12} lg={12}>
    <Divider />
  </Grid>
  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 5, py: 5 }}>
    <Alert
      variant="outlined"
      severity="warning"
      sx={{ textAlign: "center" }}
    >
      Job Is NOT Found or No Longer Exists in the System
    </Alert>
  </Grid>
</Grid>;
}
