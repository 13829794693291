import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import { APIURL } from "../../../../Context/configs";
import { SnLStore, AdminsStore } from "../../../../Store";
export default function NewAdmin() {
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetSnackMessage = SnLStore((state) => state.SetSnackMessage);
  const SetSnackBarShow = SnLStore((state) => state.SetSnackBarShow);
  const SetSnackBarType = SnLStore((state) => state.SetSnackBarType);
  const setadminslist = AdminsStore((state) => state.setadminslist);
  const SetAddAdmin = AdminsStore((state) => state.SetAddAdmin);
  const AddAdmin = AdminsStore((state) => state.AddAdmin);
  const CloseDialog = () => {
    SetAddAdmin();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "admin/NewAdmin.php", {
      method: "post",
      body: JSON.stringify({
        un: data.get("un"),
        fn: data.get("fn"),
        pw: data.get("pw"),
        pwc: data.get("cpw"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          if (item.id === 1) {
            SetSnackBarType("success");
            fetch(APIURL + "admin/ListAdmins.php")
              .then((res) => res.json())
              .then((result) => {
                setTimeout(() => {
                  CloseDialog();
                  SetLoadingShow();
                  SetSnackBarShow();
                  setadminslist(result);
                }, 2000);
              });
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              CloseDialog();
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={AddAdmin} onClose={CloseDialog} maxWidth="md" fullWidth>
      <DialogTitle color={"#2f3193"}>Add New System Admin</DialogTitle>
      <DialogContent>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
          <Divider />
        </Grid>
        <Box component="form" onSubmit={sendRequest} sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="fn"
                name="fn"
                required
                label="Fullname"
                size="small"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="un"
                name="un"
                required
                label="Username"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="pw"
                name="pw"
                required
                label="Password"
                size="small"
                type="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                id="cpw"
                name="cpw"
                required
                label="Confirm Password"
                size="small"
                type="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "end" }}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="small"
                sx={{ mx: 1 }}
              >
                Add Admin
              </Button>
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={CloseDialog}
              >
                Close Form
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
