import { Routes, Route, Navigate } from "react-router-dom";
import AdminContainer from "./Components/Manager/AdminContainer";
import { LoginPage, Application } from "./Components/PortalHome/Pages";
import { userLoginStore } from "./Store";
export default function App() {
  const isLoggedIn = userLoginStore((state) => state.isLoggedIn);
  return (
    <Routes>
      <Route
        index
        element={!isLoggedIn ? <LoginPage /> : <Navigate to="/admin/" />}
      />
      <Route path="/admin/*" element={<AdminContainer />} />
      <Route path="apply/:jid/:tid" element={<Application />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
