import { Paper, Grid, ButtonGroup, Button, Typography } from "@mui/material";
import { ApplicantsStore, SnLStore } from "../../../../Store";
import TableViewIcon from "@mui/icons-material/TableView";
import ArchiveIcon from "@mui/icons-material/Archive";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RestoreIcon from "@mui/icons-material/Restore";
import { APIURL } from "../../../../Context/configs";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default function JobsManager() {
  const VacLIst = ApplicantsStore((state) => state.VacLIst);
  const SetVacLIst = ApplicantsStore((state) => state.SetVacLIst);
  const AppCounts = ApplicantsStore((state) => state.AppCounts);
  const ApplicantsList = ApplicantsStore((state) => state.ApplicantsList);
  const SetJobEditor = ApplicantsStore((state) => state.SetJobEditor);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetSnackMessage = SnLStore((state) => state.SetSnackMessage);
  const SetSnackBarShow = SnLStore((state) => state.SetSnackBarShow);
  const SetSnackBarType = SnLStore((state) => state.SetSnackBarType);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    csvData = [
      {
        id: "#",
        fn: "Firstname",
        ln: "Lastname",
        ge: "Gender",
        dob: "Date of Birth",
        phone: "Phone",
        email: "Email",
        country: "Country",
        province: "Province",
        district: "District",
        education: "Education",
        study: "Field of Study",
        experience: "Experience",
        wfib: "Worked For Agency Before",
        nationality: "Nationality",
        languages: "Languages",
        wore: "Working relative in Agency",
        worefn: "Relative Fullname",
        worere: "Relative Relationship",
        ioa: "Interest of Application",
      },
      ...csvData,
    ];
    const ws = XLSX.utils.json_to_sheet(csvData, { skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const zipDownload = (par) => {
    SetLoadingShow();
    fetch(APIURL + "admin/GetCVS.php", {
      method: "post",
      body: JSON.stringify({
        id: par,
        withcert: VacLIst[0].with_cert,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setTimeout(() => {
          result.forEach((item) => {
            window.open(item.name, "_blank");
            SetLoadingShow(false);
          });
        }, 6000);
      });
  };
  const JobAction = (par, act) => {
    SetLoadingShow();
    fetch(APIURL + "admin/VacActions.php", {
      method: "post",
      body: JSON.stringify({
        id: par,
        at: act,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          if (item.id === 1) {
            SetSnackBarType("success");
            fetch(APIURL + "admin/GetVacancy.php", {
              method: "post",
              body: JSON.stringify({
                id: par,
              }),
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Request-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Credentials": "true",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                setTimeout(() => {
                  SetLoadingShow();
                  SetSnackBarShow();
                  SetVacLIst(result);
                }, 2000);
              });
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Paper elevation={3} sx={{ my: 2, p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ my: 1 }}>
          <Typography variant="h6">
            Total Applicants : <span className="bolder">{AppCounts}</span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={9}
          sx={{ my: 1, textAlign: "end" }}
        >
          <ButtonGroup>
            <Button
              color="success"
              size="small"
              onClick={(e) =>
                exportToCSV(
                  ApplicantsList,
                  "Applicant List For " +
                    VacLIst[0].name +
                    " (" +
                    VacLIst[0].reference +
                    ")"
                )
              }
            >
              <TableViewIcon sx={{ mx: 1 }} />
              Export As Excel
            </Button>
            <Button
              color="warning"
              size="small"
              onClick={() => zipDownload(VacLIst[0].id)}
            >
              <ArchiveIcon sx={{ mx: 1 }} />
              Download CV's
            </Button>
            {VacLIst[0].opened === 1 ? (
              <Button
                color="primary"
                size="small"
                onClick={() => JobAction(VacLIst[0].id, "disable")}
              >
                <NotInterestedIcon sx={{ mx: 1 }} /> Disable Application
              </Button>
            ) : (
              <Button
                color="primary"
                size="small"
                onClick={() => JobAction(VacLIst[0].id, "enable")}
              >
                <CheckCircleOutlineIcon sx={{ mx: 1 }} /> Enable Application
              </Button>
            )}
            {VacLIst[0].deleted === 1 ? (
              <Button
                color="error"
                size="small"
                onClick={() => JobAction(VacLIst[0].id, "restore")}
              >
                <RestoreIcon sx={{ mx: 1 }} /> Restore Job
              </Button>
            ) : (
              <Button
                color="error"
                size="small"
                onClick={() => JobAction(VacLIst[0].id, "delete")}
              >
                <DeleteIcon sx={{ mx: 1 }} /> Delete Job
              </Button>
            )}
            <Button color="secondary" size="small" onClick={SetJobEditor}>
              <EditIcon sx={{ mx: 1 }} />
              Edit Job
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
}
