import { Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { VacanciesStore } from "../../../../Store";
export default function VacHeader() {
  const SetAddAdmin = VacanciesStore((state) => state.SetAddAdmin);
  const SetEditAdmin = VacanciesStore((state) => state.SetEditAdmin);
  return (
    <Grid container spacing={1} sx={{ my: 3 }}>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          onClick={SetEditAdmin}
        >
          <AccountBalanceIcon fontSize="small" sx={{ mx: 1 }} />
          Manage Organization
        </Button>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={6}></Grid>
      <Grid item xs={6} sm={6} md={4} lg={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          onClick={SetAddAdmin}
        >
          <AddIcon fontSize="small" sx={{ mx: 1 }} />
          Add New Vacancy
        </Button>
      </Grid>
    </Grid>
  );
}
