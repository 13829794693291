import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { NavLink, useParams } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import { ApplicantsStore } from "../../../Store";
import { ActionMessage, Loadin } from "../Elements/Common";
import {
  ApplicantsList,
  JobsManager,
  JobsEditor,
} from "../Elements/Applicants";
export default function ViewApplicannts() {
  const [isLoading, setisLoading] = useState(true);
  const SetApplicantsList = ApplicantsStore((state) => state.SetApplicantsList);
  const SetVacLIst = ApplicantsStore((state) => state.SetVacLIst);
  const SetAppCounts = ApplicantsStore((state) => state.SetAppCounts);
  const SetOrgList = ApplicantsStore((state) => state.SetOrgList);
  const VacLIst = ApplicantsStore((state) => state.VacLIst);
  const { jid } = useParams();
  useEffect(() => {
    document.title = "StarsOrbit Jobs Portal - View Vanacy";
    fetch(APIURL + "admin/GetVacancy.php", {
      method: "post",
      body: JSON.stringify({
        id: jid,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetVacLIst(result);
      });
    fetch(APIURL + "admin/ListOrgs.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetOrgList(result);
      });
    fetch(APIURL + "admin/CountApplicants.php", {
      method: "post",
      body: JSON.stringify({
        id: jid,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result == null) {
        } else {
          result.forEach((item) => {
            SetAppCounts(item.TOT);
          });
        }
      });
    fetch(APIURL + "admin/ViewApplicants.php", {
      method: "post",
      body: JSON.stringify({
        id: jid,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetApplicantsList(result);
        setisLoading(false);
      });
  }, [jid]);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          {isLoading ? (
            <Paper elevation={3} className="siteLoader">
              <RingLoader />
            </Paper>
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <NavLink className="breabcrumblink" to="/admin">
                Admin Panel
              </NavLink>
              <NavLink className="breabcrumblink" to="/admin/vacancies">
                Vanacies
              </NavLink>
              <Typography color="text.primary">
                View Vacancy {VacLIst[0].name} ({VacLIst[0].reference})
              </Typography>
            </Breadcrumbs>
          )}
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <Box>
          <Typography variant="h6" color={"#2f3193"} sx={{ my: 3 }}>
            Job Info
          </Typography>
          <JobsManager />
          <Typography variant="h6" color={"#2f3193"} sx={{ my: 3 }}>
            Applicants List
          </Typography>
          <ApplicantsList />
          <JobsEditor />
        </Box>
      )}
      <ActionMessage />
      <Loadin />
    </Container>
  );
}
