import { RollerLoader } from "../../../Common/Loaders";
import { SnLStore } from "../../../../Store";

export default function Loadin() {
  const LoadingShow = SnLStore((state) => state.LoadingShow);
  return (
    <>
      {LoadingShow && (
        <div className="submitLoader">
          <RollerLoader />
        </div>
      )}
    </>
  );
}
