import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import { APIURL } from "../../../../Context/configs";
import { SnLStore, AdminsStore } from "../../../../Store";

export default function EditAdmin() {
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetSnackMessage = SnLStore((state) => state.SetSnackMessage);
  const SetSnackBarShow = SnLStore((state) => state.SetSnackBarShow);
  const SetSnackBarType = SnLStore((state) => state.SetSnackBarType);
  const setadminslist = AdminsStore((state) => state.setadminslist);
  const SetEditAdmin = AdminsStore((state) => state.SetEditAdmin);
  const EditAdmin = AdminsStore((state) => state.EditAdmin);
  const adminID = AdminsStore((state) => state.adminID);
  const adminslist = AdminsStore((state) => state.adminslist);
  const CloseDialog = () => {
    SetEditAdmin();
  };
  const sendRequest = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    fetch(APIURL + "admin/EditAdmin.php", {
      method: "post",
      body: JSON.stringify({
        id: data.get("id"),
        un: data.get("un"),
        fn: data.get("fn"),
        pw: data.get("pw"),
        pwc: data.get("cpw"),
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result.forEach((item) => {
          SetSnackMessage(item.mes);
          if (item.id === 1) {
            SetSnackBarType("success");
            fetch(APIURL + "admin/ListAdmins.php")
              .then((res) => res.json())
              .then((result) => {
                setTimeout(() => {
                  CloseDialog();
                  SetLoadingShow();
                  SetSnackBarShow();
                  setadminslist(result);
                }, 2000);
              });
          } else {
            SetSnackBarType("error");
            setTimeout(() => {
              CloseDialog();
              SetLoadingShow();
              SetSnackBarShow();
            }, 2000);
          }
        });
      });
  };
  return (
    <Dialog open={EditAdmin} onClose={CloseDialog} maxWidth="md" fullWidth>
      {adminslist
        .filter((adm) => adm.id === adminID)
        .map((row) => (
          <DialogTitle key={row.id} color={"#2f3193"}>
            Update System Admin ({row.name})
          </DialogTitle>
        ))}
      {adminslist
        .filter((adm) => adm.id === adminID)
        .map((row) => (
          <DialogContent key={row.id}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
              <Divider />
            </Grid>
            <Box component="form" onSubmit={sendRequest} sx={{ mt: 1 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-none">
                  <TextField
                    id="id"
                    name="id"
                    required
                    defaultValue={row.id}
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="fn"
                    name="fn"
                    required
                    label="Fullname"
                    size="small"
                    fullWidth
                    autoFocus
                    defaultValue={row.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="un"
                    name="un"
                    required
                    label="Username"
                    size="small"
                    fullWidth
                    defaultValue={row.username}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="pw"
                    name="pw"
                    label="Password"
                    size="small"
                    type="password"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    id="cpw"
                    name="cpw"
                    label="Confirm Password"
                    size="small"
                    type="password"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ textAlign: "end" }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    size="small"
                    sx={{ mx: 1 }}
                  >
                    Update Admin
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={CloseDialog}
                  >
                    Close Form
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        ))}
    </Dialog>
  );
}
