import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DasHApplicantsGrid } from "../../../../Context/DataGrids";
import { dashBoardStore } from "../../../../Store";
export default function DashL10Apps() {
  const l10apps = dashBoardStore((state) => state.l10apps);
  return (
    <>
      {l10apps.length == 0 ? (
        <Typography variant="h6" sx={{ textAlign: "center", py: 3 }}>
          No Data To Preview
        </Typography>
      ) : (
        <DataGrid
          rows={l10apps}
          columns={DasHApplicantsGrid}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          autoHeight
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
        />
      )}
    </>
  );
}
