import { Grid, TextField, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { ApplicantsStore } from "../../../../Store";
import { ApplicantListColAll } from "../../../../Context/DataGrids";
export default function ApplicantsList() {
  const ApplicantsList = ApplicantsStore((state) => state.ApplicantsList);
  const [ItemsPerPage] = useState(100);

  return (
    <Grid container spacing={3} sx={{ mb: 2 }}>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 2 }}>
        <DataGrid
          rows={ApplicantsList}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          columns={ApplicantListColAll}
          pageSize={ItemsPerPage}
          rowsPerPageOptions={[ItemsPerPage]}
          disableSelectionOnClick
          autoHeight
          width={"100%"}
          disableColumnMenu
          density="compact"
          getRowId={(row) => row.id}
        />
      </Grid>
    </Grid>
  );
}
