import { Box, Container, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { APIURL } from "../../../Context/configs";
import { ApplicationStore } from "../../../Store/";
import { RingLoader } from "../../Common/Loaders";
import { Loadin } from "../../Manager/Elements/Common";
import { useParams } from "react-router-dom";
import {
  ApplicationHeader,
  ApplicationSteps,
  ApplicationForm,
  ApplicationFooter,
} from "../Elements/Application";

export default function Application() {
  const [isLoading, setisLoading] = useState(true);
  const SetCountryList = ApplicationStore((state) => state.SetCountryList);
  const SetProvinceList = ApplicationStore((state) => state.SetProvinceList);
  const SetJinfo = ApplicationStore((state) => state.SetJinfo);
  const { jid } = useParams();
  const { tid } = useParams();
  useEffect(() => {
    fetch(APIURL + "application/CountryList.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetCountryList(result);
      });
    fetch(APIURL + "application/GovernorateList.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetProvinceList(result);
      });
    fetch(APIURL + "application/GetJob.php", {
      method: "post",
      body: JSON.stringify({
        id: jid,
        tk: tid,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        SetJinfo(result);
      });
    setTimeout(() => {
      setisLoading(false);
    }, 3000);
  }, []);
  return (
    <Container maxWidth="lg">
      <ApplicationHeader />
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <Box>
          <ApplicationSteps />
          <ApplicationForm />
        </Box>
      )}
      <Loadin />
      <ApplicationFooter />
    </Container>
  );
}
