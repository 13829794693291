import { create } from "zustand";
const SnLStore = create((set) => ({
  SnackBarShow: false,
  SnackBarMessage: "",
  LoadingShow: false,
  ZipLoadingShow: false,
  SnackBarType: "success",
  SetSnackBarShow: () =>
    set((state) => ({ SnackBarShow: !state.SnackBarShow })),
  SetLoadingShow: () => set((state) => ({ LoadingShow: !state.LoadingShow })),
  SetZipLoadingShow: () =>
    set((state) => ({ ZipLoadingShow: !state.ZipLoadingShow })),
  SetSnackMessage: (mes) => set((state) => ({ SnackBarMessage: mes })),
  SetSnackBarType: (t) => set((state) => ({ SnackBarType: t })),
}));

export default SnLStore;
