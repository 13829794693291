import { Box, Container } from "@mui/material";
import { TopBar, BottomBar } from "../Common/Pagebars";
import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import { userLoginStore } from "../../Store";
import { useEffect } from "react";
import { BASEURI } from "../../Context/configs";
import {
  Admins,
  DashBoard,
  LIstVacancies,
  Settings,
  ViewApplicannts,
  AllApplicants,
} from "./Pages";
export default function AdminContainer() {
  const LoggedIn = userLoginStore((state) => state.isLoggedIn);
  useEffect(() => {
    if (!LoggedIn) {
      window.location.href = BASEURI;
    }
  }, [LoggedIn]);
  return (
    <Box sx={{ display: "flex", flexGrow: 0 }}>
      <Navbar />
      <Container maxWidth="auto" disableGutters>
        <TopBar title="Admin Control Panel" />
        <div className="sitContent">
          <Routes>
            <Route path="/" element={<DashBoard />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/admins" element={<Admins />} />
            <Route path="/vacancies" element={<LIstVacancies />} />
            <Route path="/allapps" element={<AllApplicants />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/applicants/:jid/" element={<ViewApplicannts />} />
          </Routes>
        </div>
        <BottomBar />
      </Container>
    </Box>
  );
}
