import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
export default function IOMExp() {
  const WFIB = ApplicationStore((state) => state.WFIB);
  const WRFI = ApplicationStore((state) => state.WRFI);
  const RelName = ApplicationStore((state) => state.RelName);
  const RelRelation = ApplicationStore((state) => state.RelRelation);
  const SetWFIB = ApplicationStore((state) => state.SetWFIB);
  const SetWRFI = ApplicationStore((state) => state.SetWRFI);
  const SetRelName = ApplicationStore((state) => state.SetRelName);
  const SetRelRelation = ApplicationStore((state) => state.SetRelRelation);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetIOMInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    SetWFIB(data.get("wfib"));
    SetRelRelation(data.get("rn"));
    SetRelName(data.get("rr"));
    setTimeout(() => {
      SetActiveStep(ActiveStep + 1);
      SetLoadingShow();
    }, 2000);
  };
  const Goback = () => {
    SetActiveStep(ActiveStep - 1);
  };
  return (
    <Box component="form" onSubmit={SetIOMInfo} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6">Agency Experience</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="wfib"
            id="wfib"
            label="Worked For Agency Before"
            required
            size="small"
            fullWidth
            select
            autoFocus
            defaultValue={WFIB}
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="wrfi"
            id="wrfi"
            label="Regardless of the nature of the relationship, do you have any relatives currently employed by Agency or SOC?"
            required
            size="small"
            fullWidth
            select
            defaultValue={WRFI}
            onChange={(event) => SetWRFI(event.target.value)}
          >
            <MenuItem value={0}>No</MenuItem>
            <MenuItem value={1}>Yes</MenuItem>
          </TextField>
        </Grid>
        {WRFI === 1 && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="rn"
                id="rn"
                label="Relative Name"
                required
                size="small"
                fullWidth
                defaultValue={RelName}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="rr"
                id="rr"
                label="Relative Relationship"
                required
                size="small"
                fullWidth
                defaultValue={RelRelation}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography sx={{py:5,px:2,color:"maroon",fontSize:"small"}}>* Please be aware that failure to disclose any relationships with employees of Agency or SOC may result in disqualification of your application.</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ my: 1, textAlign: "end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="appbut"
            sx={{ mx: 1 }}
            onClick={Goback}
          >
            <NavigateBeforeIcon sx={{ mx: 1 }} />
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            className="appbut"
          >
            Next
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
