import { Container, CssBaseline } from "@mui/material";
import { LoginHeader, LoginForm, LoginFooter } from "../Elements/LoginPage";
export default function LoginPage() {
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <LoginHeader />
        <LoginForm />
      </Container>
      <Container component="main" maxWidth="lg">
        <LoginFooter />
      </Container>
    </>
  );
}
