import { create } from "zustand";
const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
const Today = (date) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};
const ApplicationStore = create((set) => ({
  CountryList: [],
  ProvinceList: [],
  Jinfo: [],
  ActiveStep: 0,
  FirstName: "",
  LastName: "",
  Gender: "Male",
  Dob: Today(new Date()),
  PhoneNumber: "",
  Email: "",
  Country: 105,
  Province: 1,
  District: "",
  EducationLevel: "Primary Degree",
  FieldOfStudy: "",
  YearsOfExp: 0,
  Nationality: "",
  Langauges: "",
  WFIB: 0,
  WRFI: 0,
  RelName: "",
  RelRelation: "",
  IOA: "",
  CVFile: "",
  CertFile: "",
  CVExt: "",
  CertExt: "",
  RespMess: 1,
  randNum: 0,
  SetRespMess: (st) => set((state) => ({ RespMess: st })),
  SetRrandNum: (st) => set((state) => ({ randNum: st })),
  SetCVEXT: (st) => set((state) => ({ CVExt: st })),
  SetCertExt: (st) => set((state) => ({ CertExt: st })),
  SetCVFile: (st) => set((state) => ({ CVFile: st })),
  SetCertFile: (st) => set((state) => ({ CertFile: st })),
  SetIOA: (st) => set((state) => ({ IOA: st })),
  SetRelRelation: (st) => set((state) => ({ RelRelation: st })),
  SetRelName: (st) => set((state) => ({ RelName: st })),
  SetWRFI: (st) => set((state) => ({ WRFI: st })),
  SetWFIB: (st) => set((state) => ({ WFIB: st })),
  SetLangauges: (st) => set((state) => ({ Langauges: st })),
  SetNationality: (st) => set((state) => ({ Nationality: st })),
  SetYearsOfExp: (st) => set((state) => ({ YearsOfExp: st })),
  SetFieldOfStudy: (st) => set((state) => ({ FieldOfStudy: st })),
  SetEducationLevel: (st) => set((state) => ({ EducationLevel: st })),
  SetDistrict: (st) => set((state) => ({ District: st })),
  SetProvince: (st) => set((state) => ({ Province: st })),
  SetCountry: (st) => set((state) => ({ Country: st })),
  SetEmail: (st) => set((state) => ({ Email: st })),
  SetPhoneNumber: (st) => set((state) => ({ PhoneNumber: st })),
  SetDob: (st) => set((state) => ({ Dob: st })),
  SetGender: (st) => set((state) => ({ Gender: st })),
  SetLastName: (st) => set((state) => ({ LastName: st })),
  SetFirstName: (st) => set((state) => ({ FirstName: st })),
  SetCountryList: (data) => set((state) => ({ CountryList: data })),
  SetProvinceList: (data) => set((state) => ({ ProvinceList: data })),
  SetJinfo: (data) => set((state) => ({ Jinfo: data })),
  SetActiveStep: (st) => set((state) => ({ ActiveStep: st })),
}));

export default ApplicationStore;
