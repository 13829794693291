import { create } from "zustand";
const ApplicantsStore = create((set) => ({
  ApplicantsList: [],
  VacLIst: [],
  OrgList: [],
  AppCounts: 0,
  JobEditor: false,
  SetApplicantsList: (data) => set((state) => ({ ApplicantsList: data })),
  SetJobEditor: () => set((state) => ({ JobEditor: !state.JobEditor })),
  SetVacLIst: (data) => set((state) => ({ VacLIst: data })),
  SetOrgList: (data) => set((state) => ({ OrgList: data })),
  SetAppCounts: (st) => set((state) => ({ AppCounts: st })),
}));

export default ApplicantsStore;
