import { Grid, Typography, Paper, Box } from "@mui/material";
import { dashBoardStore } from "../../../../Store";
import ReorderIcon from "@mui/icons-material/Reorder";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import BadgeIcon from "@mui/icons-material/Badge";
export default function DashWidgets() {
  const WID = dashBoardStore((state) => state.widgets);
  return (
    <>
      {WID.map((w) => (
        <Grid key="widlister" container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <ReorderIcon fontSize="large" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Total Vacancies</Typography>
                  <Typography>{w.TJ}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <FactCheckIcon fontSize="large" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Opened Vacancies</Typography>
                  <Typography>{w.TOTOP}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <SubtitlesOffIcon fontSize="large" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Closed Vacancies</Typography>
                  <Typography>{w.TOTCL}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#0d47a1",
                color: "#fff",
                px: 1,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <BadgeIcon fontSize="large" />
                </Box>
                <Box sx={{ flexGrow: 2, textAlign: "right" }}>
                  <Typography variant="h6">Overall Applications</Typography>
                  <Typography>{w.TA}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
