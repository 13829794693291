import { IconButton, Badge } from "@mui/material";
import { NavLink } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
export const DasHApplicantsGrid = [
  { field: "id", headerName: "#", flex: 1, sortable: false },
  {
    field: "app_id",
    headerName: "Application #",
    flex: 2,
    sortable: false,
  },
  {
    field: "reference",
    headerName: "Job Reference",
    flex: 2,
    sortable: false,
  },
  {
    field: "name",
    headerName: "Job Name",
    flex: 3,
    sortable: false,
  },
  {
    field: "firstname",
    headerName: "First name",
    flex: 2,
    sortable: false,
  },
  {
    field: "lastname",
    headerName: "Last name",
    flex: 2,
    sortable: false,
  },
];
export const JobsListerGrid = [
  { field: "id", headerName: "#", flex: 2, sortable: false },
  {
    field: "reference",
    headerName: "Job Reference",
    flex: 2,
    sortable: true,
  },
  {
    field: "org_name",
    headerName: "Organiztion",
    flex: 2,
    sortable: true,
  },
  {
    field: "name",
    headerName: "Job Name",
    flex: 2,
    sortable: true,
  },
  {
    field: "joburl",
    headerName: "Job URL",
    flex: 4,
    sortable: false,
    renderCell: (params) =>
      "https://jobs.my-soc.org/apply/" + params.row.id + "/" + params.row.token,
  },
  {
    field: "end_at",
    headerName: "End Date",
    flex: 2,
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    sortable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <>
        {params.row.deleted === 1 ? (
          <Badge color="error" badgeContent="Deleted" />
        ) : params.row.opened === 0 ? (
          <Badge color="warning" badgeContent="Closed" />
        ) : (
          <Badge color="success" badgeContent="Opened" />
        )}
      </>
    ),
  },
  {
    field: "actions",
    headerName: "View",
    flex: 1,
    sortable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <NavLink className="navlink" to={"/admin/applicants/" + params.row.id}>
        <IconButton size="small" color="primary" variant="outlined">
          <RemoveRedEyeIcon />
        </IconButton>
      </NavLink>
    ),
  },
];
export const ApplicantListCol = [
  { field: "id", headerName: "#", flex: 2, sortable: false },
  {
    field: "fn",
    headerName: "First Name",
    flex: 2,
    sortable: true,
  },
  {
    field: "ln",
    headerName: "Last Name",
    flex: 2,
    sortable: true,
  },
  {
    field: "ge",
    headerName: "Gender",
    flex: 2,
    sortable: false,
  },
  {
    field: "dob",
    headerName: "Date of Birth",
    flex: 2,
    sortable: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 2,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
    sortable: true,
  },
];
export const ApplicantListColAll = [
  { field: "id", headerName: "#", flex: 2, sortable: false },
  {
    field: "jobref",
    headerName: "Job Reference",
    flex: 2,
    sortable: true,
  },
  {
    field: "jobname",
    headerName: "Job Name",
    flex: 2,
    sortable: true,
  },
  {
    field: "fn",
    headerName: "First Name",
    flex: 2,
    sortable: true,
  },
  {
    field: "ln",
    headerName: "Last Name",
    flex: 2,
    sortable: true,
  },
  {
    field: "ge",
    headerName: "Gender",
    flex: 2,
    sortable: false,
  },
  {
    field: "dob",
    headerName: "Date of Birth",
    flex: 2,
    sortable: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 2,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 2,
    sortable: true,
  },
];
