import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useState } from "react";
import { APIURL } from "../../../../../Context/configs";
import axios from "axios";

export default function CV() {
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const Jinfo = ApplicationStore((state) => state.Jinfo);
  const FirstName = ApplicationStore((state) => state.FirstName);
  const LastName = ApplicationStore((state) => state.LastName);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetCVFile = ApplicationStore((state) => state.SetCVFile);
  const SetCertFile = ApplicationStore((state) => state.SetCertFile);
  const SetCVEXT = ApplicationStore((state) => state.SetCVEXT);
  const SetCertExt = ApplicationStore((state) => state.SetCertExt);
  const randNum = ApplicationStore((state) => state.randNum);
  const [UpError, SetUpError] = useState(false);
  const [UpDone, SetUpDone] = useState(false);
  const [UpDone2, SetUpDone2] = useState(false);
  const StartUpload = (event) => {
    SetLoadingShow();
    const formData = new FormData();
    formData.append("cv", event.target.files[0]);
    formData.append("jid", Jinfo[0].id);
    formData.append("fn", FirstName);
    formData.append("ln", LastName);
    formData.append("rn", randNum);
    axios
      .post(APIURL + "application/UploadCVTOEMP.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        response.data.forEach((item) => {
          if (item.id === 1) {
            SetLoadingShow();
            SetCVFile(item.message);
            SetCVEXT(item.cvext)
            if (Jinfo[0].with_cert === 1) {
              SetUpDone2(true);
            } else {
              SetUpDone(true);
            }
          } else {
            SetLoadingShow(false);
            SetUpError(true);
          }
        });
      });
  };
  const StartUpload2 = (event) => {
    SetLoadingShow();
    const formData = new FormData();
    formData.append("cv", event.target.files[0]);
    formData.append("jid", Jinfo[0].id);
    formData.append("fn", FirstName);
    formData.append("ln", LastName);
    formData.append("rn", randNum);
    axios
      .post(APIURL + "application/UploadCertificate.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(function (response) {
        response.data.forEach((item) => {
          if (item.id === 1) {
            SetLoadingShow();
            SetCertFile(item.message);
            SetCertExt(item.certext);
            SetUpDone(true);
          } else {
            SetLoadingShow(false);
            SetUpError(true);
          }
        });
      });
  };
  const Goback = () => {
    SetActiveStep(ActiveStep - 1);
  };
  const GoNext = () => {
    SetActiveStep(ActiveStep + 1);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6">Upload Files</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography>Upload Your CV</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          size="small"
          id="pdf"
          name="pdf"
          autoComplete="pdf"
          helperText="File Name Must Be Letters and Numbers only no Special Charecter is allowed"
          type="file"
          onChange={StartUpload}
        />
        {UpError && <Typography>Cannot Upload this File</Typography>}
      </Grid>
      {UpDone2 && (
        <>
          {Jinfo[0].with_cert === 1 && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography>Upload Your Certificate</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                size="small"
                id="pdf"
                name="pdf"
                autoComplete="pdf"
                helperText="File Name Must Be Letters and Numbers only no Special Charecter is allowed"
                type="file"
                onChange={StartUpload2}
              />
              {UpError && <Typography>Cannot Upload this File</Typography>}
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{ my: 1, textAlign: "end" }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          className="appbut"
          sx={{ mx: 1 }}
          onClick={Goback}
        >
          <NavigateBeforeIcon sx={{ mx: 1 }} />
          Previous
        </Button>
        {UpDone && (
          <Button
            variant="contained"
            color="primary"
            onClick={GoNext}
            size="small"
            className="appbut"
          >
            Next
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
