import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
export default function PersonalInfo() {
  const FirstName = ApplicationStore((state) => state.FirstName);
  const LastName = ApplicationStore((state) => state.LastName);
  const Gender = ApplicationStore((state) => state.Gender);
  const Dob = ApplicationStore((state) => state.Dob);
  const SetFirstName = ApplicationStore((state) => state.SetFirstName);
  const SetLastName = ApplicationStore((state) => state.SetLastName);
  const SetGender = ApplicationStore((state) => state.SetGender);
  const SetDob = ApplicationStore((state) => state.SetDob);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetRrandNum = ApplicationStore((state) => state.SetRrandNum);
  const generatePassword = () => {
    let charset = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let newPassword = "";
    for (let i = 0; i < 8; i++) {
      newPassword += charset.charAt(
        Math.floor(Math.random() * charset.length)
      );
    }
    SetRrandNum(newPassword);
    console.log(newPassword)
  };
  const SetPerInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    SetFirstName(data.get("fn"));
    SetLastName(data.get("ln"));
    SetGender(data.get("ge"));
    SetDob(data.get("dob"));
    generatePassword()
    setTimeout(() => {
      SetActiveStep(ActiveStep + 1);
      SetLoadingShow();
    }, 2000);
  };
  return (
    <Box component="form" onSubmit={SetPerInfo} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6">Personal Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="fn"
            id="fn"
            label="First Name"
            required
            size="small"
            fullWidth
            autoFocus
            defaultValue={FirstName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="ln"
            id="ln"
            label="Last Name"
            required
            size="small"
            fullWidth
            defaultValue={LastName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="ge"
            id="ge"
            label="Gender"
            required
            size="small"
            fullWidth
            select
            defaultValue={Gender}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="dob"
            id="dob"
            label="Date of Birth"
            required
            size="small"
            fullWidth
            type="date"
            defaultValue={Dob}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ my: 1, textAlign: "end" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            className="appbut"
          >
            Next
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
