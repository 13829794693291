import { Container, Grid, Breadcrumbs, Typography, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import { AdminsStore } from "../../../Store";
import {
  ListAdmins,
  NewAdmin,
  EditAdmin,
  DeleteAdmin,
} from "../Elements/Admins";
import { ActionMessage, Loadin } from "../Elements/Common";
export default function Admins() {
  const [isLoading, setisLoading] = useState(true);
  const setadminslist = AdminsStore((state) => state.setadminslist);
  useEffect(() => {
    document.title = "StarsOrbit Jobs Portal - System Admins";
    fetch(APIURL + "admin/ListAdmins.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        setadminslist(result);
      });
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">System Admins</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <ListAdmins />
      )}
      <NewAdmin />
      <EditAdmin />
      <DeleteAdmin />
      <ActionMessage />
      <Loadin />
    </Container>
  );
}
