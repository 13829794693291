import {
  Box,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { ApplicationStore } from "../../../../Store";

export default function ApplicationSteps() {
  const Jinfo = ApplicationStore((state) => state.Jinfo);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const ViewHolder = (param) => {
    if (param === null || param[0].deleted === 1) {
      return (
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          No Vacany Available
        </Typography>
      );
    } else {
      return (
        <Typography variant="body">
          Apply For Vacancy {param[0].name} ({param[0].reference})
        </Typography>
      );
    }
  };
  const ViewHolder2 = (param) => {
    if (param === null || param[0].deleted === 1 || param[0].opened === 0) {
      return null;
    } else {
      return (
        <Paper elevation={3} sx={{ my: 2, p: 2 }}>
          <Stepper activeStep={ActiveStep}>
            <Step>
              <StepLabel>Perosnal Information</StepLabel>
            </Step>
            <Step>
              <StepLabel>Contact Information</StepLabel>
            </Step>
            <Step>
              <StepLabel>Education And Nationality</StepLabel>
            </Step>
            <Step>
              <StepLabel>Agency Experience</StepLabel>
            </Step>
            <Step>
              <StepLabel>Interest of Application</StepLabel>
            </Step>
            <Step>
              <StepLabel>Upload Files</StepLabel>
            </Step>
            <Step>
              <StepLabel>Preview Information</StepLabel>
            </Step>
            <Step>
              <StepLabel>Finish</StepLabel>
            </Step>
          </Stepper>
        </Paper>
      );
    }
  };
  return (
    <Box>
      <Paper
        elevation={3}
        sx={{ my: 2, p: 2, backgroundColor: "#000", color: "#fff" }}
      >
        {ViewHolder(Jinfo)}
      </Paper>
      {ViewHolder2(Jinfo)}
    </Box>
  );
}
