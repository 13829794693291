import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Divider, List, IconButton, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SettingsIcon from "@mui/icons-material/Settings";
import ListIcon from "@mui/icons-material/List";
import BallotIcon from "@mui/icons-material/Ballot";
import { NavLink } from "react-router-dom";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const DrawerBody = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function Navbar(props) {
  const [NavOpen, SetNavOpen] = useState(false);

  const handleDrawer = () => {
    SetNavOpen(!NavOpen);
  };
  const p = props.priv;
  return (
    <DrawerBody variant="permanent" open={NavOpen}>
      <DrawerHeader>
        <IconButton onClick={handleDrawer} sx={{ mr: 1 }}>
          {NavOpen ? <CloseSharpIcon /> : <MenuSharpIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List className="text-small">
        <NavLink className="navlink" to="/admin/dashboard" title="Dashboard">
          <DashboardSharpIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Dashboard
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/admin/admins" title="System Admins">
          <SupervisorAccountIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            System Admins
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/admin/allapps" title="All Applicants">
          <ListIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            All Applicants
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/admin/vacancies" title="Vacancies">
          <BallotIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Vacancies
          </Typography>
        </NavLink>
        <NavLink className="navlink" to="/admin/settings" title="Settings">
          <SettingsIcon fontSize="small" sx={{ mx: 1 }} />
          <Typography variant="body2" sx={{ opacity: NavOpen ? 1 : 0 }}>
            Settings
          </Typography>
        </NavLink>
      </List>
    </DrawerBody>
  );
}
