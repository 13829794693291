import { AppBar, Toolbar, Typography, IconButton, Paper } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { userLoginStore } from "../../../Store";
import Cookies from "js-cookie";
import { BASEURI } from "../../../Context/configs";
export default function TopBar(props) {
  const LogOutAction = userLoginStore((state) => state.setIsLoggedIn);
  const DoLogout = () => {
    Cookies.remove("loggedIn");
    LogOutAction();
    window.location.href = BASEURI;
  };
  return (
    <Paper elevation={3}>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            className="text-soc"
          >
            {props.title}
          </Typography>
          <IconButton
            onClick={DoLogout}
            size="large"
            edge="start"
            color="error"
            aria-label="menu"
            sx={{ m: 0, p: 1 }}
          >
            <LogoutOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Paper>
  );
}
