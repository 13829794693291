import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RingLoader } from "../../Common/Loaders";
import { NavLink } from "react-router-dom";
import { APIURL } from "../../../Context/configs";
import { VacanciesStore } from "../../../Store";
import { ActionMessage, Loadin } from "../Elements/Common";
import { VacHeader, VaLister, NewOrg, NewVac } from "../Elements/Vacancies";

export default function LIstVacancies() {
  const [isLoading, setisLoading] = useState(true);
  const setVacList = VacanciesStore((state) => state.setVacList);
  const setOrgsList = VacanciesStore((state) => state.setOrgsList);
  useEffect(() => {
    document.title = "StarsOrbit Jobs Portal - Vanacies";
    fetch(APIURL + "admin/ListOrgs.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setOrgsList(result);
      });
    fetch(APIURL + "admin/ListVacancies.php", {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setisLoading(false);
        setVacList(result);
      });
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink className="breabcrumblink" to="/admin">
              Admin Panel
            </NavLink>
            <Typography color="text.primary">Vanacies</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {isLoading ? (
        <Paper elevation={3} className="siteLoader">
          <RingLoader />
        </Paper>
      ) : (
        <Box>
          <Typography variant="h5" color={"#2f3193"} sx={{ my: 3 }}>
            Vacancies
          </Typography>
          <VacHeader />
          <Typography variant="h5" sx={{ mb: 3 }}>
            List Vacancies
          </Typography>
          <VaLister />
        </Box>
      )}
      <NewVac />
      <NewOrg />
      <ActionMessage />
      <Loadin />
    </Container>
  );
}
