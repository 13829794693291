import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { ApplicationStore, SnLStore } from "../../../../../Store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
export default function EducationNat() {
  const EducationLevel = ApplicationStore((state) => state.EducationLevel);
  const FieldOfStudy = ApplicationStore((state) => state.FieldOfStudy);
  const YearsOfExp = ApplicationStore((state) => state.YearsOfExp);
  const Nationality = ApplicationStore((state) => state.Nationality);
  const Langauges = ApplicationStore((state) => state.Langauges);
  const SetLangauges = ApplicationStore((state) => state.SetLangauges);
  const SetEducationLevel = ApplicationStore(
    (state) => state.SetEducationLevel
  );
  const SetFieldOfStudy = ApplicationStore((state) => state.SetFieldOfStudy);
  const SetYearsOfExp = ApplicationStore((state) => state.SetYearsOfExp);
  const SetNationality = ApplicationStore((state) => state.SetNationality);
  const SetActiveStep = ApplicationStore((state) => state.SetActiveStep);
  const ActiveStep = ApplicationStore((state) => state.ActiveStep);
  const SetLoadingShow = SnLStore((state) => state.SetLoadingShow);
  const SetEdInfo = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    SetLoadingShow();
    SetEducationLevel(data.get("el"));
    SetFieldOfStudy(data.get("fos"));
    SetYearsOfExp(data.get("yoe"));
    SetNationality(data.get("nat"));
    SetLangauges(data.get("lan"));
    setTimeout(() => {
      SetActiveStep(ActiveStep + 1);
      SetLoadingShow();
    }, 2000);
  };
  const Goback = () => {
    SetActiveStep(ActiveStep - 1);
  };
  return (
    <Box component="form" onSubmit={SetEdInfo} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6">Education And Nationality</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            name="el"
            id="el"
            label="Education Level"
            required
            size="small"
            fullWidth
            select
            autoFocus
            defaultValue={EducationLevel}
          >
            <MenuItem value="Primary Degree">Primary Degree</MenuItem>
            <MenuItem value="Secondary Degree">Secondary Degree</MenuItem>
            <MenuItem value="Technical Diploma">Technical Diploma</MenuItem>
            <MenuItem value="Bachelor 's Degree">Bachelor 's Degree</MenuItem>
            <MenuItem value="Master's Degree">Master's Degree</MenuItem>
            <MenuItem value="Doctorate">Doctorate</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="fos"
            id="fos"
            label="Field Of Study"
            required
            size="small"
            fullWidth
            defaultValue={FieldOfStudy}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="yoe"
            id="yoe"
            label="Years Of Experience"
            required
            size="small"
            fullWidth
            type="number"
            defaultValue={YearsOfExp}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="nat"
            id="nat"
            label="Nationality"
            required
            size="small"
            fullWidth
            defaultValue={Nationality}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="lan"
            id="lan"
            label="Langauges"
            required
            size="small"
            fullWidth
            defaultValue={Langauges}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ my: 1, textAlign: "end" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="appbut"
            sx={{ mx: 1 }}
            onClick={Goback}
          >
            <NavigateBeforeIcon sx={{ mx: 1 }} />
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            className="appbut"
          >
            Next
            <NavigateNextIcon sx={{ mx: 1 }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
